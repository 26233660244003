
import { defineComponent } from 'vue'
import AdminPanelNavMenu from '@/components/molecules/AdminPanelNavMenu.vue'
import View from '@/assets/svg/view.svg?inline'
import Edit from '@/assets/svg/edit.svg?inline'
import Delete from '@/assets/svg/delete.svg?inline'
import Search from '@/assets/svg/search.svg?inline'
import Picture from '@/assets/test/picture.svg?inline'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import SortArrow from '@/assets/svg/sort-arrow.svg?inline'
import AddInstructor from '@/assets/svg/add-instructor.svg?inline'
import Pagination from '@/components/molecules/Pagination.vue'
import vClickOutside from 'click-outside-vue3'
import Calendar from '@/components/molecules/Calendar.vue'
import SelectArrow from '@/assets/svg/select-arrow.svg?inline'

export default defineComponent({
  name: 'Students',
  data () {
    return {
      page: 1,
      studentsMeta: null,
      studentsData: {},
      instructorsData: {},
      attachInstructorsData: {},
      inviteInstructorsData: [],
      errorstudents: null,
      isModalDelete: false,
      isModalChooseInstructor: false,
      isModalChooseInstructorNewStudent: false,
      isModalCopyCode: false,
      searchData: '',
      searchDataInstructors: '',
      sortData: '',
      instructorName: '',
      generateStateCode: null,
      selectedNewDate: null,
      selectedNewDateSubsqribe: null,
      isCalendarVisible: false,
      isCalendarSubsqribeVisible: false,
      showSubscription: null,
      copied: false,
      errorAttachInstructor: null
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    AdminPanelNavMenu,
    View,
    Edit,
    Delete,
    Search,
    Picture,
    DefaultPopup,
    SortArrow,
    Pagination,
    Calendar,
    AddInstructor,
    SelectArrow
  },
  watch: {
    searchData () {
      this.getStudents()
      this.page = 1
    },
    searchDataInstructors () {
      this.getInstructors()
      this.page = 1
    }
  },
  mounted () {
    this.getStudents()
    this.getInstructors()
  },
  methods: {
    getStudents (params, sort) {
      let searchParam = `?page=${this.page}&search=`
      if (this.searchData !== '') {
        searchParam = `?page=${this.page}&search=` + this.searchData
      }
      if (sort === undefined) {
        sort = 'id'
      }
      params = searchParam + '&sort=' + sort
      this.$store.dispatch('students/getStudents', params).then(
        (res) => {
          this.studentsData = res.data
          this.studentsMeta = res.meta
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    getInstructors (params) {
      params = '?search=' + this.searchDataInstructors
      this.$store.dispatch('instructors/getInstructors', params).then(
        (res) => {
          this.instructorsData = res.data
          this.instructorsMeta = res.meta
        },
        (error) => {
          this.errorInstructors = error.response.data.errors
        }
      )
    },
    viewStudent (id) {
      this.$router.push('/admin/student?student=' + id)
    },
    editstudent (id) {
      this.$router.push('/admin/edit-student?student=' + id)
    },
    openDeletePopup (id) {
      this.studentId = id
      this.isModalDelete = true
    },
    handleCloseModalDelete () {
      this.isModalDelete = false
    },
    openPopupChoosenInstructor (id, instructors) {
      this.attachInstructorsData = instructors
      this.studentId = id
      this.isModalChooseInstructor = true
    },
    handleCloseModalChooseInstructor () {
      this.isModalChooseInstructor = false
    },
    openPopupChoosenInstructorNewStudent (id) {
      this.studentId = id
      this.isModalChooseInstructorNewStudent = true
    },
    handleCloseModalChooseInstructorNewStudent () {
      this.isModalChooseInstructorNewStudent = false
    },
    openPopupCopyCode () {
      this.isModalCopyCode = true
    },
    handleCloseModalCopyCode () {
      this.isModalCopyCode = false
    },
    deleteStudent () {
      this.$store.dispatch('students/deleteStudent', this.studentId).then(
        () => {
          this.getStudents()
          this.isModalDelete = false
        },
        (error) => {
          this.errorInstructor = error.response.data.errors
        }
      )
    },
    isCheckedInstructor (id) {
      return this.attachInstructorsData?.some((instructor) => instructor.id === id)
    },
    attachInstructors (id) {
      if (!Array.isArray(this.attachInstructorsData)) {
        this.attachInstructorsData = []
      }
      const index = this.attachInstructorsData.findIndex(
        (instructor) => instructor.id === id
      )
      if (index !== -1) {
        this.attachInstructorsData.splice(index, 1)
      } else {
        const instructorToAdd = this.instructorsData?.find(
          (instructor) => instructor.id === id
        )
        if (instructorToAdd) {
          this.attachInstructorsData.push(instructorToAdd)
        }
      }
      const data = {
        id: this.studentId,
        instructors: [id]
      }
      this.$store.dispatch('students/attachInstructors', data).then(
        () => {
          this.getStudents()
          this.isModalDelete = false
        },
        (error) => {
          this.errorAttachInstructor = error.response?.data?.message || 'Error attaching instructor'
        }
      )
    },
    unAttachInstructors (id) {
      const index = this.attachInstructorsData.findIndex(
        (instructor) => instructor.id === id
      )
      if (index !== -1) {
        this.attachInstructorsData.splice(index, 1)
      } else {
        const instructorToAdd = this.instructorsData.find(
          (instructor) => instructor.id === id
        )
        if (instructorToAdd) {
          this.attachInstructorsData.push(instructorToAdd)
        }
      }
      const ids = []
      ids.push(id)
      const data = {
        id: this.studentId,
        instructors: ids
      }
      this.$store.dispatch('students/detachInstructors', data).then(
        () => {
          this.getStudents()
          this.isModalDelete = false
        },
        (error) => {
          this.errorAttachInstructor = error.response.data.message
        }
      )
    },
    generateInviteCodeStudent () {
      const data = {
        instructors: this.inviteInstructorsData
      }
      this.$store.dispatch('students/setInviteCode', data).then(
        (res) => {
          this.generateStateCode = res.data
          this.handleCloseModalChooseInstructorNewStudent()
          this.openPopupCopyCode()
        },
        (error) => {
          this.errorInviteCode = error.response.data.message
        }
      )
    },
    handlePage (page) {
      this.page = page
      this.getStudents()
    },
    isChecked (id) {
      if (this.instructorName) {
        return this.instructorName.some(selected => selected.id === id)
      }
    },
    copyText () {
      const textToCopy = this.generateStateCode.code
      const textarea = document.createElement('textarea')
      textarea.value = textToCopy
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('copy')
      document.body.removeChild(textarea)
      this.copied = true

      setTimeout(() => {
        this.copied = false
      }, 3000)
    },
    selectInstructors (id) {
      this.inviteInstructorsData.push(id)
    },
    formatDate (dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)
      const day = date.getDate()
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date)
      const year = date.getFullYear()
      return `${day} ${month} ${year}`
    },
    selectDateFill (date, month, year) {
      const selectedDate = new Date(year, month, date.value)
      const currentDate = new Date()
      if (selectedDate > currentDate) {
        return
      }
      if (date.value !== '') {
        const selectedDate = new Date(year, month, date.value)
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
          '-' +
          (selectedDate.getMonth() + 1) +
          '-' +
          selectedDate.getDate() >=
          this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
          this.selectedEndDate = null
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`
        this.getAnalytics()
      }
    },
    selectDateFillSubsqribe (date, month, year) {
      const selectedDate = new Date(year, month, date.value)
      const currentDate = new Date()
      if (selectedDate > currentDate) {
        return
      }
      if (date.value !== '') {
        const selectedDate = new Date(year, month, date.value)
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() +
          '-' +
          (selectedDate.getMonth() + 1) +
          '-' +
          selectedDate.getDate() >=
          this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() +
            '-' +
            (selectedDate.getMonth() + 1) +
            '-' +
            selectedDate.getDate()
          this.selectedEndDate = null
        }
      }
      if (this.selectedEndDate) {
        this.filtersDate = `&start_date=${this.selectedStartDate}&end_date=${this.selectedEndDate}`
        this.getAnalytics()
      }
    },
    toggleCalendar () {
      this.isCalendarVisible = !this.isCalendarVisible
    },
    closeCalendar () {
      this.isCalendarVisible = false
    },
    toggleCalendarSubsqribe () {
      this.isCalendarVisible = !this.isCalendarVisible
    },
    closeCalendarSubsqribe () {
      this.isCalendarSubsqribeVisible = false
    },
    resetFilters () {
      window.location.reload()
    },
    checkedShowSubscription () {
      let showSubscriptionNew = 1
      if (!this.showSubscription === false) {
        showSubscriptionNew = 0
      }
      this.filters = this.filters + `&filter[inactive_subscription]=${showSubscriptionNew}`
      this.getPaymentLogs()
    }
  }
})
